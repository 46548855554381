import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet amb un capell de coloració bru ambre a bru vermellós, que passa a groguenc quan s’asseca, de 1 a 4 cm de diàmetre. Davall aquest es troben les làmines de color groc-brunenc, denses, de sinuades a rectament adnades. El peu és de color semblant al capell amb un anell membranós poc desenvolupat. Les espores són de color bru, amigdaliformes, finament verrucoses, de 7-10 x 4,5-6,5 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      